export default {
  data () {
    return {
      m$taskHeaderCollapsed: false,
      m$taskHeaderHasShadow: false,
      m$tabsTopDistance: 200,
    }
  },
  methods: {
    m$handleTaskHeaderShadow ({hasShadow, height}) {
      this.m$taskHeaderHasShadow = hasShadow
      this.m$tabsTopDistance = height + 50
    },
    m$handleTaskHeaderCollapsed ({collapsed, height}) {
      this.m$taskHeaderCollapsed = collapsed
      this.m$tabsTopDistance = height + 50
    },
  }
}
