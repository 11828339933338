<template>
  <modal
    v-model="isVisible"
    :title="title"
    size="sm"
    :backdrop="false"
    @show="open"
    @hide="close"
  >
    <slot />
    <div slot="footer">
      <button
        type="button"
        class="zord-btn btn zord-btn-default btn-default"
        @click="cancel"
      >
        <span>{{ cancelText }}</span>
      </button>
      <button
        type="button"
        class="zord-btn btn zord-btn-primary btn-primary"
        data-action="auto-focus"
        @click="confirm"
      >
        <span>{{ okText }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import BaseModal from './BaseModal'

export default {
  mixins: [
    BaseModal
  ],
  props: {
    title: { type: String, default: 'Tytuł' },
    okText: { type: String, default: 'Tak' },
    cancelText: { type: String, default: 'Anuluj' },
    subject: { type: [Array, String], required: true }
  },
  methods: {
    open () {
      this.$emit('show')
    },
    close () {
      this.$parent.$emit('close')
    },
    cancel () {
      this.$parent.$emit('close')
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm', this.subject)
    }
  }
}
</script>
