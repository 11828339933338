export default {
  methods: {
    isImage (fileType) {
      let imageMimeTypes = [
        'image/bmp',
        'image/x-bmp',
        'image/x-bitmap',
        'image/x-xbitmap',
        'image/x-win-bitmap',
        'image/x-windows-bmp',
        'image/ms-bmp',
        'image/x-ms-bmp',
        'application/bmp',
        'application/x-bmp',
        'application/x-win-bitmap',
        'image/jpeg',
        'image/jpg',
        'image/pjpeg',
        'image/vnd.swiftview-jpeg',
        // 'image/tif',
        // 'image/x-tif',
        // 'image/tiff',
        // 'image/x-tiff',
        // 'application/tif',
        // 'application/x-tif',
        // 'application/tiff',
        // 'application/x-tiff',
        'image/png',
        'application/png',
        'application/x-png',
        'image/gif'
      ]

      return imageMimeTypes.includes(fileType)
    },
    isImageByExtension (extension) {
      let extensions = [
        'bmp',
        'jpg',
        'jpeg',
        // 'tif',
        // 'tiff',
        'png',
        'gif'
      ]
      return extensions.includes(extension)
    }
  }
}
