<template>
  <div
    class="form-group"
    :class="[{ 'has-error': errorMessage, 'has-success' : !errorMessage && value }, { 'required-field': required }]"
  >
    <label :for="id">
      {{ label }}
    </label>
    <field-money
      :id="id"
      :class="`invoice-${name} money-type-field`"
      :clear="clear"
      :name="name"
      :model="model"
      :schema="getSchema"
      @input="updateValue"
    />
    <span
      v-show="errorMessage"
      class="help-block"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import FieldMoney from '../../../bundles/share/form/type/fieldMoney'

export default {
  components: {
    FieldMoney
  },
  props: {
    /** Flaga do wyzerowania pola finansowego */
    clear: { type: Boolean, default: false },
    /** Komunikat wyświetlana podczas błędów wykrytych przez walidator */
    errorMessage: { type: null, required: true },
    /** Etykieta pola wyświetlana w widoku */
    label: { type: String, required: true },
    /** Określa atrybuty 'id' oraz 'name' tagu html: <input id="" name=""> */
    name: { type: String, required: true },
    /** Ustawia atrybut 'required-field' dla walidatora */
    required: { type: Boolean, default: false },
    /** Określa typ pola */
    type: { type: String, default: 'text' },
    /** Przechowuje wartość pola formularza */
    value: null
  },
  computed: {
    id: function () {
      return `${this.name}-${this._uid}`
    },
    model () {
      let modelObj = {}
      modelObj[this.name] = this.value
      return modelObj
    },
    getSchema () {
      return {
        model: `${this.name}`,
        inputName: `${this.name}`,
        type: 'money'
      }
    }
  },
  methods: {
    /** Aktualizuje wartość parametru 'value' i emituje zdarzenie 'input' */
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style>
.money-type-field {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
